body {
  margin: 0;
  font-family: "Heebo", sans-serif;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.fs-7 {
  font-size: 12px;
}

.icon {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1.2rem;
}

@media screen and (max-width: 1199px) {
  .column {
    column-count: 2;
    column-width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .column {
    column-count: 1;
    column-width: 100%;
  }
}
.text-secon {
  color: #072f45 !important;
}
.bg-secon {
  background-color: #072f45 !important;
}
.bg-secon:hover {
  background-color: #041d2b !important;
}
